@import url(//fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700,300italic);

html {
  /* next two for sticky footer */
  position: relative;
  min-height: 100%;
  min-width: 300px;
}

body {
  background: #f5f5f5;
  color: rgb(34, 34, 34);
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 18px;
  /* Margin bottom by footer height */
  margin-bottom: 65px;
}

h1,
h2,
h3 {
  font-family: 'Open Sans Condensed';
  margin-top: 0px;
}

.post {
  font-family: 'Open Sans Condensed', sans-serif; /*Source Sans Pro*/
  font-size: 18px;
  text-align: justify;
}

hr.post {
  border-width: 3px;
  border-color: #203b4d;
  border-color: #9aa4ad;
}
