footer {
  background-color: #222222;
  position: absolute;
  bottom: 0;
  width: 100%;

  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
  /* Set the fixed height of the footer here */
  /* height: 65px; */
  font-size: 13px;
  color: #f5f5f5;
}

.sharewidth {
  display: flex;
  justify-content: space-between;
}

img.social {
  width: 32px;
  height: 32px;
}
