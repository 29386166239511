/* Because I dont want to include bootstrap or font-awesome */

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.icon-bar {
  background-color: #fff;
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}
.icon-bar + .icon-bar {
  margin-top: 4px;
}
