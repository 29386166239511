.gallery-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 15px;
}

.gallery-header {
  padding-top: 20px;
}

.gallery-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.gallery-links > a {
  margin-right: 5px;
  text-align: center;
}

.swipebox {
  padding: 5px 5px 0px 0px;
}
