#myCarousel {
  margin-bottom: 20px;
}

.carousel-inner > .item > img {
  margin: 0 auto;
}

span.playsnoemp {
  /* no emphasis */
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
}
